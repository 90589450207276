jQuery(window).on("load", function() {    

    $('.js-equal-height--book-cards').each(function(){
        var highestBox = 0;
        $(this).find('.c-feature__book-card header').each(function(){
            if($(this).height() > highestBox){
                highestBox = $(this).height();
            }
        })
        $(this).find('.c-feature__book-card header').height(highestBox);
    });

});
