jQuery(window).on("load", function() {

    // init Isotope
    var $grid = $('.js-isotope--grid').isotope({
        // options
        itemSelector: '.o-grid__item',
        layoutMode: 'fitRows'
    });

    // filter functions
    var filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function() {
        var number = $(this).find('.number').text();
        return parseInt( number, 10 ) > 50;
        },
        // show if name ends with -ium
        ium: function() {
        var name = $(this).find('.name').text();
        return name.match( /ium$/ );
        }
    };   

    // store filter for each group
    var filters = {};

    $('.js-isotope--filter').on( 'change', function( event ) {
        var $select = $( event.target );
        // get group key
        var filterGroup = $select.attr('value-group');
        // set filter for group
        filters[ filterGroup ] = event.target.value;
        // combine filters
        var filterValue = concatValues( filters );
        // set filter for Isotope
        $grid.isotope({ filter: filterValue });
    });

    // flatten object by concatting values
    function concatValues( obj ) {
        var value = '';
        for ( var prop in obj ) {
            value += obj[ prop ];
        }
        return value;
    }


    // // bind filter button click
    // $('.js-isotope--filter').on( 'click', 'button', function() {
    //     var filterValue = $( this ).attr('data-filter');
    //     // use filterFn if matches value
    //     filterValue = filterFns[ filterValue ] || filterValue;
    //     $grid.isotope({ filter: filterValue });

    //     // remove all is-active classes        
    //     // $('.c-projects__isotope .o-grid__item').each(function(i, obj) {
    //     //     $(this).removeClass('is-active');
    //     // });

    //     // // add to only filtered
    //     // var filteredItems = $grid.isotope('getFilteredItemElements');
    //     // filteredItems.forEach(elm => elm.classList.add('is-active'));
    // });

    // // change is-checked class on buttons
    // $('.js-isotope--filter').each( function( i, buttonGroup ) {
    //     var $buttonGroup = $( buttonGroup );
    //     $buttonGroup.on( 'click', 'button', function() {
    //     $buttonGroup.find('.is-active').removeClass('is-active');
    //     $( this ).addClass('is-active');
    //     });
    // });

});

// Alternative to isotope (to see if the css layout could be used)
// jQuery(window).on("load", function() {

//     // Filters
//     $(document).on('click', '.c-projects__filter__tag', function (e) {
//         e.preventDefault();

//         // clear filters
//         $('.c-projects__filter__tag').each(function(i, obj) {
//             $(this).removeClass('is-active');        
//         });

//         // add active to clicked filter
//         $(this).addClass('is-active');        

//         // get filter id
//         var itemId = $(this).attr("data-filter");        

//         // strip first character (needed in this case as using isotope class with dot but can be removed)
//         var id = itemId.substring(1, itemId.length);

//         // check unstripped filter id to see if its all value
//         if (itemId == '*') {

//             $('.c-projects__isotope .o-grid__item').each(function(i, obj) {
//                 $(this).show();           
//             });
        
//         } else {

//             // check each item
//             $('.c-projects__isotope .o-grid__item').each(function(i, obj) {
//                 if ($(this).hasClass(id) ) {
//                     $(this).hide();
//                     $(this).removeClass('is-active'); 
//                 } else {
//                     $(this).show();
//                     $(this).addClass('is-active'); 
//                 }            
//                 // if ($(this).attr('data-title') == id) {
//                 //     $(this).hide();
//                 //     $(this).removeClass('is-active'); 
//                 // } else {
//                 //     $(this).show();
//                 //     $(this).addClass('is-active'); 
//                 // }            
//             });

//         }

        
                        
//     });

// });