jQuery(window).on("load", function() {


    jQuery('.c-product__accordion__titles a').on('click', function(e){
        e.preventDefault();    
        
        // check if active already
        if ( !$(this).hasClass('is-active') ) {

            // remove state from all other links
            jQuery('.c-product__accordion__titles a').each(function(i, obj) {
                jQuery(this).removeClass('is-active');
            });
            
            // add to this link
            jQuery(this).toggleClass('is-active');

            // get id
            var id = $(this).attr("data-title");

            // check contents for matching id and show / hide accordingly
            jQuery('.c-product__accordion__content').each(function(i, obj) {
                if (jQuery(this).attr('data-content') == id) {
                    jQuery(this).show();
                } else {
                    jQuery(this).hide();
                }            
            });

        }


    });

});
