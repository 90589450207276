jQuery(document).ready(function($) {
    // Listen for a click event on the shipping method radio inputs
    // $(document).on('click', 'input[name^="shipping_method"]', function() {
    //     // Remove the 'checked' attribute from all shipping method radio inputs
    //     $('input[name^="shipping_method"]').removeAttr('checked');
    //
    //     // Add the 'checked' attribute to the selected shipping method radio input
    //     $(this).attr('checked', 'checked');
    //
    //     // Trigger the change event to update the cart and checkout totals
    //     $(this).trigger('change');
    //
    // });
    $('.add_to_cart_button').click(ajaxCallSingleProduct);

    // Update quantity number when changed

    function ajaxCallSingleProduct(e) {

        e.preventDefault();

        var product_id = $(this).data('product_id');

        var quantity = $(this).closest('.cart').find('input[name="quantity"]').val();

        var data = {
            action: 'add_to_cart',
            product_id: product_id,
            quantity: quantity
        };

        $.ajax({
            type: 'POST',
            url: wc_add_to_cart_params.ajax_url,
            data: data,
            beforeSend: function() {
                // Show loading spinner or do any pre-request tasks
            },
            success: function(response) {
                // Handle the response from the server
                if (response.error) {
                    // Error occurred, handle accordingly
                    window.location.href = response.product_url; // Redirect to product page to show errors
                } else {
                    // Product added to cart successfully, handle accordingly

                    // Update the mini cart HTML
                    if (typeof response.mini_cart !== 'undefined') {
                        $('.c-mini-cart').html(response.mini_cart);
                    }

                    if (typeof response.cart_quantity !== 'undefined') {
                        $('.c-page-head__cart__amount').html(response.cart_quantity);
                    }

                    // Show the mini cart popup
                    $('.c-mini-cart__wrapper').addClass('is-open');
                }
            },
            error: function(xhr, status, error) {
                // Handle AJAX error
            },
            complete: function() {
                // Perform any post-request tasks
            }
        });
    };

    // Listen for a change event on the shipping method radio inputs
    $(document).on('change', 'input[name^="shipping_method"]', function() {
        var shippingMethod = $(this).val();

        // AJAX request to update the shipping method
        $.ajax({
            type: 'POST',
            url: my_ajax_object.ajax_url,
            data: {
                action: 'update_checkout_summary',
                shipping_method: shippingMethod,
            },
            success: function(response) {
                // Update the checkout summary price with the returned data
                if (response.success) {
                    $('.woocommerce-checkout-review-order .total-amount .woocommerce-Price-amount').html(response.data.order_total);

                    $('.cart-total').html(response.data.cart);

                    // Trigger the 'updated_checkout' event to update other elements on the checkout page if needed
                    $('body').trigger('updated_checkout');
                }
            },
            error: function(xhr, status, error) {
                // Handle AJAX error
                console.log('Error:', xhr.responseText);
            },
        });
    });
});
