jQuery(window).on("load", function() {

    // breakpoints
    var $xs = 375;
    var $s = 479;
    var $m =  768;
    var $l = 1024;
    var $xl = 1440;
    var $xxl = 1900;     

    // Foreground images slider
    if (!!jQuery('.js-slick--foreground').length) {
        // Full width slick sliders
        jQuery('.js-slick--foreground').slick({
            adaptiveHeight: false,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            fade: true,
            infinite: true
        });
    }

    // Hero slider
    if (!!jQuery('.js-slick--hero').length) {
        // Full width slick sliders
        jQuery('.js-slick--hero').slick({
            adaptiveHeight: false,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            fade: true,
            infinite: true,
            responsive: [
                {
                    breakpoint: $xl,
                    settings: {
                        arrows: false
                    }
                }
            ]
        });
    }

    // Our Projects slider
    if (!!jQuery('.js-slick--our-projects').length) {  
        
        // Fix custom dots is active bug
        var activeClass = 'slick-active',
            ariaAttribute = 'aria-hidden';
        $( '.js-slick--our-projects' )
        .on( 'init', function() {
            $( '.slick-dots li:first-of-type' ).addClass( activeClass ).attr( ariaAttribute, false );
        } )
        .on( 'afterChange', function( event, slick, currentSlide ) {
            var $dots = $( '.slick-dots' );
            $( 'li', $dots ).removeClass( activeClass ).attr( ariaAttribute, true );
            $dots.each( function() {
                $( 'li', $( this ) ).eq( currentSlide ).addClass( activeClass ).attr( ariaAttribute, false );
            } );
        } );

        jQuery('.js-slick--our-projects').slick({
            adaptiveHeight: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            appendDots: '.js-slick-dots--our-projects',
            fade: true,
            infinite: true
        });

        $('.js-slick--prev--our-projects').click(function(e){
            e.preventDefault();
            $('.js-slick--our-projects').slick('slickPrev');
        })

        $('.js-slick--next--our-projects').click(function(e){
            e.preventDefault();
            $('.js-slick--our-projects').slick('slickNext');
        })  
    }

    // Latest News
    if (!!$('.js-slick--news').length) {

        // Fix custom dots is active bug
        var activeClass = 'slick-active',
            ariaAttribute = 'aria-hidden';
        $( '.js-slick--news' )
        .on( 'init', function() {
            $( '.slick-dots li:first-of-type' ).addClass( activeClass ).attr( ariaAttribute, false );
        } )
        .on( 'afterChange', function( event, slick, currentSlide ) {
            var $dots = $( '.slick-dots' );
            $( 'li', $dots ).removeClass( activeClass ).attr( ariaAttribute, true );
            $dots.each( function() {
                $( 'li', $( this ) ).eq( currentSlide ).addClass( activeClass ).attr( ariaAttribute, false );
            } );
        } );

        $('.js-slick--news').slick({
            dots: true,
            appendDots: '.js-slick-dots--news',
            arrows: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.js-slick--prev--news').click(function(e){
            e.preventDefault();
            $('.js-slick--news').slick('slickPrev');
        })
          
        $('.js-slick--next--news').click(function(e){
            e.preventDefault();
            $('.js-slick--news').slick('slickNext');
        })
    }

    // Upcoming Events
    if (!!$('.js-slick--news').length) {

        // Fix custom dots is active bug
        var activeClass = 'slick-active',
            ariaAttribute = 'aria-hidden';
        $( '.js-slick--events' )
        .on( 'init', function() {
            $( '.slick-dots li:first-of-type' ).addClass( activeClass ).attr( ariaAttribute, false );
        } )
        .on( 'afterChange', function( event, slick, currentSlide ) {
            var $dots = $( '.slick-dots' );
            $( 'li', $dots ).removeClass( activeClass ).attr( ariaAttribute, true );
            $dots.each( function() {
                $( 'li', $( this ) ).eq( currentSlide ).addClass( activeClass ).attr( ariaAttribute, false );
            } );
        } );

        $('.js-slick--events').slick({
            dots: true,
            appendDots: '.js-slick-dots--events',
            arrows: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.js-slick--prev--events').click(function(e){
            e.preventDefault();
            $('.js-slick--events').slick('slickPrev');
        })
          
        $('.js-slick--next--events').click(function(e){
            e.preventDefault();
            $('.js-slick--events').slick('slickNext');
        })
    }


    // Sponsors
    if (!!$('.js-slick--sponsors').length) {
        $('.js-slick--sponsors').slick({
            dots: true,
            arrows: true,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 5,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });
    }


    if (!!$('.js-slick--eventbrite-events').length) {
        $('.js-slick--eventbrite-events').slick({
            dots: true,
            arrows: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true
                    }
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true
                    }
                },
                {
                    breakpoint: $s,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        });
    }

    // Project Gallery
    if (!!$('.js-slick--project-gallery').length) {
        $('.js-slick--project-gallery').slick({
            dots: true,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false
                    }
                }
            ]
        });
    }

    // Similar Projects
    if (!!jQuery('.js-slick--similar-projects').length) {
        $('.js-slick--similar-projects').slick({
            dots: true,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: $l,
                    settings: "unslick"
                },
                {
                    breakpoint: $s,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true
                    }
                }
            ]
        });
    }

    // Previous Melas
    if (!!$('.js-slick--mela').length) {

        // Fix custom dots is active bug
        var activeClass = 'slick-active',
            ariaAttribute = 'aria-hidden';
        $( '.js-slick--mela' )
        .on( 'init', function() {
            $( '.slick-dots li:first-of-type' ).addClass( activeClass ).attr( ariaAttribute, false );
        } )
        .on( 'afterChange', function( event, slick, currentSlide ) {
            var $dots = $( '.slick-dots' );
            $( 'li', $dots ).removeClass( activeClass ).attr( ariaAttribute, true );
            $dots.each( function() {
                $( 'li', $( this ) ).eq( currentSlide ).addClass( activeClass ).attr( ariaAttribute, false );
            } );
        } );

        $('.js-slick--mela').slick({
            dots: true,
            appendDots: '.js-slick-dots--mela',
            arrows: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.js-slick--prev--mela').click(function(e){
            e.preventDefault();
            $('.js-slick--mela').slick('slickPrev');
        })
          
        $('.js-slick--next--mela').click(function(e){
            e.preventDefault();
            $('.js-slick--mela').slick('slickNext');
        })
    }


    // Value Props
    if (!!$('.js-slick--value-props').length) {
        $('.js-slick--value-props').slick({
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            rows: 0,
            responsive: [
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                        autoplaySpeed: 6000
                    }
                }
            ]
        });
    }

    // Features
    if (!!$('.js-slick--features').length) {
        $('.js-slick--features').slick({
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            rows: 0,
            responsive: [
                {
                    breakpoint: $l,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true
                    }
                },
                {
                    breakpoint: $m,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        });
    }


    // Our Projects slider new
    if (!!jQuery('.js-slick--our-projects-new').length) {  
        

        jQuery('.js-slick--our-projects-new').slick({
            adaptiveHeight: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            fade: true,
            infinite: true
        });
    }

});
