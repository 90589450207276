jQuery(window).on("load", function() {

    // function checkSize(){

    //     if($(window).innerWidth() <= 1024) { 

    //     }

    // }
    // checkSize();
    // jQuery(window).resize(checkSize);

    jQuery(document).on('click', '.js-back-btn', function(e){
        e.preventDefault();
        window.history.back();
    });

});
