// // Navbar toggle
// let opened = null
// const toggleVisibility = e => e.classList.toggle('is-open')
// const handleDropdown = e => {
//   const clickedItem = e
//   toggleVisibility(clickedItem)
//   if (!opened) {
//     opened = clickedItem
//   } else if (opened == clickedItem) {
//     opened = null
//   } else {
//     toggleVisibility(opened)
//     opened = clickedItem
//   }
// }

// // Hide dropdown if clicked outside element
// const handleClick = e => {
//   if (e.target.className.includes('c-navbar__dropdown')) {
//     handleDropdown(e.target)
//   } else if (opened) {
//     toggleVisibility(opened)
//     opened = null
//   }
// }
// document.addEventListener('click', handleClick)

// // jquery to check if element actually exists
// if (!!jQuery('.js-hamburger').length) {

//   // Hamburger toggle
//   document.querySelector('.js-hamburger').onclick = function(){
//       document.querySelector('.c-navbar').classList.toggle("hamburger-opened");
//       document.querySelector('.c-hamburger').classList.toggle("is-active");
//   }
  
// }

// jquery only version of Hamburger toggle
if (!!jQuery('.js-hamburger').length) {
  jQuery(document).on('click', '.js-hamburger', function(e){
    e.preventDefault();
    jQuery('.c-navbar').toggleClass('hamburger-opened');  
    jQuery('.c-hamburger').toggleClass('is-active');
    jQuery('.c-page-head').toggleClass('hamburger-opened');    
  }); 
}

// jquery only version of dropdown toggle
$(document).ready(function(){
  $('.c-navbar__dropdown').click(function(e){
      e.preventDefault();
      $(this).toggleClass('is-open');
  });
});

$(document).click(function(e){
  if(!$(e.target).hasClass('c-navbar__dropdown')){
      $('.c-navbar__dropdown').removeClass('is-open');
  }
});


// Mini cart
// jQuery(window).on("load", function() {
//   var headerHeight = $('.c-page-head').outerHeight();
//   $('.c-mini-cart__wrapper').css('margin-top', headerHeight);
// });

$('.js-mini-cart').on('click', function(e){
  e.preventDefault();
  $('.c-mini-cart__wrapper').toggleClass("is-open");
});