jQuery(window).on("load", function() {

    // add scroll padding class to html
    var $headerH = $('.c-page-head').outerHeight() * 2;
    $('html').css('scroll-padding-top', $headerH);

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
    
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
    
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };
    

    function checkSize(){

        if($(window).innerWidth() >= 479) { 

            $(window).on('resize scroll', function() {
                if ($('.c-page-foot').isInViewport()) {
                    //console.log('content block is in viewport.', $(this))
                    jQuery(".c-sidebar").css('display', 'none' ); 
                } else {
                    // do something else
                    jQuery(".c-sidebar").css('display', 'block' ); 
                }
            });
            
            var headerHeight = $('.c-page-head').outerHeight();
            jQuery(".c-sidebar").css('top', headerHeight );
            $(this).scroll(function() {
                if( jQuery(document).scrollTop() > 100 ){
                    jQuery(".c-sidebar").css('top', headerHeight - 16 );
                } else {
                    jQuery(".c-sidebar").css('top', headerHeight );
                }
            });      

            // Cache selectors
            var lastId,
            topMenu = $(".js-scroll-menu"),
            topMenuHeight = topMenu.outerHeight()+75,
            // All list items
            menuItems = topMenu.find("a"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function(){
            var item = $($(this).attr("href"));
            if (item.length) { return item; }
            });

            // Bind click handler to menu items
            // so we can get a fancy scroll animation
            // menuItems.click(function(e){
            // var href = $(this).attr("href"),
            //     offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
            // $('html, body').stop().animate({ 
            //     scrollTop: offsetTop
            // }, 300);
            // e.preventDefault();
            // });

            // Bind to scroll
            $(window).scroll(function(){
                // Get container scroll position
                var fromTop = $(this).scrollTop()+topMenuHeight;

                // Get id of current scroll item
                var cur = scrollItems.map(function(){
                    if ($(this).offset().top < fromTop)
                    return this;
                });
                // Get the id of the current element
                cur = cur[cur.length-1];
                var id = cur && cur.length ? cur[0].id : "";

                if (lastId !== id) {
                    lastId = id;
                    // Set/remove active class
                    menuItems
                        .parent().removeClass("is-active")
                        .end().filter("[href='#"+id+"']").parent().addClass("is-active");
                }                   
            });

        }

        if($(window).innerWidth() <= 479) {
            $('.c-sidebar__title').on('click', function(e){
                e.preventDefault();
                $('.c-sidebar').toggleClass("is-active");
            });
        }

    }
    checkSize();
    jQuery(window).resize(checkSize);

});