$(document).ready(function() {
    'use strict';    

    // if scroll class used
    if (!!jQuery('.c-page-head--scroll').length) {
        // when the document is scrolled 
        $(this).scroll(function() {
            // add scroll class
            // if( jQuery(document).scrollTop() > headerHeight ){
            if( jQuery(document).scrollTop() > 100 ){
                jQuery(".c-page-head--scroll").addClass("is-active");
            // remove
            } else {
                jQuery(".c-page-head--scroll").removeClass("is-active");
            }

        });

        // set mobile menu for scroll
        //jQuery(".c-hamburger").addClass("scroll");
    }    

});